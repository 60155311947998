<template>
   <b-navbar toggleable="lg" fixed="top">
      <b-navbar-brand to="/"><img class="img-fluid" src="../assets/img/logo.png" alt="DigiMatra Logo"/>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>


        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item to="/">HOME</b-nav-item>
            <b-nav-item to="/about/">ABOUT US</b-nav-item>
            <b-nav-item to="/service/">EXPERTISE</b-nav-item>
            <b-nav-item to="/clients/">ASSOCIATED BRANDS</b-nav-item>
            <b-nav-item to="/contact/">CONNECT WITH US</b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
</template>

<script>
export default {
  name: "header"
}
</script>

<style scoped>

</style>