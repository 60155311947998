<template>
  <div>
<!--    <loading-animation v-if="loading"></loading-animation>-->
<!--    <div v-else>-->
    <div>
      <Header/>
          <transition name="fade" mode="out-in">
            <router-view/>
          </transition>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";

export default {
  name: "base",
  components: {Footer, Header},
  // data() {
  //   return {
  //     loading: true
  //   }
  // },
  // mounted() {
  //   setTimeout(this.countdown, 5000);
  // },
  // methods: {
  //   countdown() {
  //     this.loading = false;
  //   }
  // }
};
</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


</style>