<template>
  <footer class="footer">
    <div class="container-fluid">
      <p class="mb-0">© SureStory. All rights reserved. </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer"
}
</script>

<style scoped>

</style>